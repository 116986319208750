<template>
  <NavBar/>
  <div id="app">
    <div class="app-wrapper">
      <loading/>
      <Validation/>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Carousel from "@/components/Carousel.vue";
import Loading from "@/components/Loading.vue";
import Validation from "@/components/Validation.vue";

export default {
  name: 'App',
  components: {
    Validation,
    Loading,
    NavBar,
    Carousel
  },
};
</script>

<style>

</style>