import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/css/main.scss';


// Créez l'application Vue et montez-la sur l'élément avec l'ID 'app'
const app = createApp(App);

// Utilisez le système de routage
app.use(router);

// Montez l'application sur l'élément avec l'ID 'app'
app.mount('#app');
