<template>
    <a class="icon fab fa-facebook facebook" href="https://www.facebook.com/Association.okonda/" target="_blank"></a>
    <a class="icon fab fa-instagram instagram" href="https://www.instagram.com/association_okonda/?hl=fr%5C" target="_blank"></a>
    <a class="icon fab fa-linkedin linkedin" href="https://www.linkedin.com/company/association-okonda/" target="_blank"></a>
</template>

<style scoped>
a {
  font-size: 18px; /* Taille de la police des icônes */
  margin: 10px;
  text-decoration: none;
}

/* Classes de couleurs Font Awesome */
.facebook {
  color: #1877f2; /* Couleur Facebook */
}

.instagram {
  color: #bc2a8d; /* Couleur Instagram */
}

.linkedin {
  color: #0077b5; /* Couleur LinkedIn */
}

/* Ajoute d'autres styles ou couleurs selon tes préférences */
</style>
