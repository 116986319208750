// translationService.js
import translations from './translations.json';

let defaultLanguage = 'en';
let currentLanguage = getLanguage();

export function setLanguage(language)
{
    localStorage.setItem('language', language);
    currentLanguage = language;

    // Reload the page with the updated URL
    window.location.reload();
}

export function getLanguage()
{
    var language = localStorage.getItem('language');
    var currentLang;

    if (language == null)
    {
        localStorage.setItem('language', defaultLanguage);
        currentLang = defaultLanguage;
    } else
        currentLang = language;

    return currentLang;
}

export function translate(key)
{
    return translations[currentLanguage][key] || key;
}

export function getTranslations()
{
    return translations;
}
