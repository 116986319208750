<template>
  <div class="carousel">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in items" :key="index" class="swiper-slide">
          <div class="image-container">
            <img :src="item.image" :style="{ marginTop: `${item.yPosition}%` }" alt="image"/>
          </div>
        </div>
      </div>
    </div>
    <div class="gradient-mask"/>
    <div class="swiper-pagination"></div>
  </div>

</template>

<script>
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle';

import P1 from "@/assets/images/carousel/P1.jpg";
import P2 from "@/assets/images/carousel/P2.jpg";
import P3 from "@/assets/images/carousel/P3.jpg";
import P4 from "@/assets/images/carousel/P4.jpg";
import P5 from "@/assets/images/carousel/P5.jpg";

const imagePaths = [
  {image: P1, yPosition: 0},
  {image: P2, yPosition: 0},
  {image: P3, yPosition: 0},
  {image: P4, yPosition: 0},
  {image: P5, yPosition: 0},
];

export default {
  name: 'Carousel',
  data()
  {
    // Fonction pour mélanger aléatoirement les éléments d'un tableau
    const shuffleArray = (array) =>
    {
      for (let i = array.length - 1; i > 0; i--)
      {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    return {
      items: shuffleArray(imagePaths),
      swiper: null,
    };
  },
  mounted()
  {
    this.swiper = new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade', // Effet de fondu
      speed: 500, // Durée de l'animation
    });
  },
};
</script>

<style scoped>
.carousel {
  z-index: 2;
  height: 95vh;
  overflow: hidden;
  position: relative;
  transition: all 500ms;
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 100%;

}

img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.gradient-mask {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 15vh;
  background: linear-gradient(to bottom, transparent, #ffffff);
}
</style>
