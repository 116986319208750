<template>
  <div class="select">
    <select v-model="selectedLanguage" @change="changeLanguage">
      <option value="fr">{{ translate('French') }}</option>
      <option value="en">{{ translate('English') }}</option>
      <option value="de">{{ translate('Deutsch') }}</option>
    </select>
  </div>
</template>

<script>
import {setLanguage, getLanguage, translate} from "@/scripts/Langue/translationService";

export default {
  data()
  {
    return {
      selectedLanguage: getLanguage(), // Langue par défaut
    };
  },
  methods: {
    translate,
    changeLanguage()
    {
      setLanguage(this.selectedLanguage);
    },
  },
};
</script>

<style scoped>
select {
  /* Reset Select */
  appearance: none;
  outline: none; /* Correction : utiliser 'none' au lieu de la valeur '10px red' */
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 0.5em;
  background: rgba(55, 143, 126, 0.34);
  cursor: pointer;

}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 10em;
  height: 1.5em;
  border-radius: 0.25em;
  overflow: hidden;
}

/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25em;
  transition: 0.25s all ease;
  pointer-events: none;
}

/* Transition */
.select:hover::after {
  color: #f39c12;
}
</style>
